<template>
    <v-app>
        
        <v-main>
            <BuscarEstadoDocumentos />
        </v-main>
    </v-app>
</template>
<script>
import BuscarEstadoDocumentos from '../components/comunes/BuscarEstadoDocumentos.vue'

export default {
    components: {
        BuscarEstadoDocumentos,
    },
    data(){
        return {
            
        }
    },
    created(){
        
    }
}
</script>